import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'
import i18n from "./i18n"
import Vuex from 'vuex'

import {
  LayoutPlugin, 
  ModalPlugin,
  ButtonPlugin,
  FormPlugin, 
  FormCheckboxPlugin, 
  FormFilePlugin,
  FormGroupPlugin,
  FormInputPlugin, 
  InputGroupPlugin, 
  FormSelectPlugin,
  FormTextareaPlugin, 
  TablePlugin, 
  FormRadioPlugin,
  ToastPlugin,
  BadgePlugin
} from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import VueDayjs from 'vue-dayjs-plugin'
import 'dayjs/locale/fr'

import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

Vue.config.productionTip = false

Vue.use(Vuex)


Vue.use(LayoutPlugin)
Vue.use(ModalPlugin)
Vue.use(ButtonPlugin)
Vue.use(FormPlugin)
Vue.use(FormCheckboxPlugin)
Vue.use(FormFilePlugin)
Vue.use(FormGroupPlugin)
Vue.use(FormInputPlugin)
Vue.use(InputGroupPlugin)
Vue.use(FormTextareaPlugin)
Vue.use(TablePlugin)
Vue.use(FormSelectPlugin)
Vue.use(FormRadioPlugin)
Vue.use(ToastPlugin)
Vue.use(BadgePlugin)

Vue.use(VueDayjs)

Vue.component('custom-loading-component', Loading)

Vue.prototype.$eventHub = new Vue()

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')


